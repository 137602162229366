/* eslint-disable no-undef */
import React from "react";
import { useState, useEffect, useRef  } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MDBIcon, MDBCol } from "mdbreact";

import Shuffle from "shufflejs";
import PropTypes from "prop-types";

// import SadFace from '../../images/icons/nao-encontrado.svg'
import { MDBBtn, MDBBtnGroup } from "mdbreact";

import CardCurso from "../CardCurso/CardCurso";
import { ConvertInstitutoToArea } from "../../helpers/Formatters";
import { GGgetMode } from "../../services/gglocalstorage";

import "./FiltroCurso.css"
/**
 * Botão de inscrições para os cursos de pós-graduação.
 * @constructor
 * @param {Array<object>} cursos - Lista de objetos de cursos.
 */



export default class FiltroCurso extends React.Component {
	constructor(props) {
		super(props);
		this.cursos = props.cursos;
		this.state = {
			tiposFiltroVisible: false,
			titulacaoFiltroVisible: false,
			modalidadeFiltroVisible: false,
		  };
	}

	toggleTiposFiltro = () => {
		this.setState((prevState) => ({
		  tiposFiltroVisible: !prevState.tiposFiltroVisible,
		}));
	  };
	  
	  toggleTitulacaoFiltro = () => {
		this.setState((prevState) => ({
		  titulacaoFiltroVisible: !prevState.titulacaoFiltroVisible,
		}));
	  };
	  
	  toggleModalidadeFiltro = () => {
		this.setState((prevState) => ({
		  modalidadeFiltroVisible: !prevState.modalidadeFiltroVisible,
		}));
	  };
	  

	// Lógica do filtro front-end.
	componentDidMount() {
		// Componente que realiza a filtragem no DOM.
		class CustomFilter {
			constructor(element) {
				this.shuffle = new Shuffle(element, {
					easing: "cubic-bezier(0.165, 0.840, 0.440, 1.000)",
					gutterWidth: 0,
				});

				this.tipos = Array.from(
					document.querySelectorAll("#cursos-tipo-filtro button")
				);
				this.modalidades = Array.from(
					document.querySelectorAll("#cursos-modalidade-filtro button")
				);
				this.titulacoes = Array.from(
					document.querySelectorAll("#cursos-titulacao-filtro button")
				);

				this.filters = {
					tipos: [],
					modalidades: [],
					titulacoes: [],
				};

				this.searchBar = document.querySelector("#cursos-busca-filtro");

				// Caso não tenha nenhum item.
				this.shuffle.on(Shuffle.EventType.LAYOUT, function (e) {
					if (e.shuffle.visibleItems === 0) {
						console.log("Nothing found");
					}
				});

				this._bindEventListeners();
			}

			_bindEventListeners() {
				this._onTipoChange = this._handleTipoChange.bind(this);
				this._onModalidadeChange = this._handleModalidadeChange.bind(this);
				this._onTitulacaoChange = this._handleTitulacaoChange.bind(this);

				this.tipos.forEach(function (button) {
					button.addEventListener("click", this._onTipoChange);
				}, this);

				this.modalidades.forEach(function (button) {
					button.addEventListener("click", this._onModalidadeChange);
				}, this);

				this.titulacoes.forEach(function (button) {
					button.addEventListener("click", this._onTitulacaoChange);
				}, this);

				this.searchBar.addEventListener(
					"keyup",
					this._handleSearchKeyup.bind(this)
				);
			}

			// ---- TIPO ----
			_getCurrentTipoFilters() {
				return this.tipos
					.filter(function (button) {
						return button.classList.contains("active");
					})
					.map(function (button) {
						return button.getAttribute("data-tipo");
					});
			}

			_handleTipoChange(evt) {
				var button = evt.currentTarget;

				document.querySelector("#cursos-tipo-filtro").value = "";
				// Treat these buttons like radio buttons where only 1 can be selected.
				if (button.classList.contains("active")) {
					button.classList.remove("active");
				} else {
					this.tipos.forEach(function (btn) {
						btn.classList.remove("active");
					});

					button.classList.add("active");
				}

				this.filters.tipos = this._getCurrentTipoFilters();
				this.filter();
			}

			// ---- Modalidade ----
			_getCurrentModalidadeFilters() {
				return this.modalidades
					.filter(function (button) {
						return button.classList.contains("active");
					})
					.map(function (button) {
						
						return button.getAttribute("data-modalidade");
					});
			}

			_handleModalidadeChange(evt) {
				var button = evt.currentTarget;

				document.querySelector("#cursos-modalidade-filtro").value = "";
				// Treat these buttons like radio buttons where only 1 can be selected.
				if (button.classList.contains("active")) {
					button.classList.remove("active");
				} else {
					this.modalidades.forEach(function (btn) {
						btn.classList.remove("active");
					});

					button.classList.add("active");
				}

				this.filters.modalidades = this._getCurrentModalidadeFilters();
				this.filter();
			}

			// ---- TITULACAO ----
			_getCurrentTitulacaoFilters() {
				return this.titulacoes
					.filter(function (button) {
						return button.classList.contains("active");
					})
					.map(function (button) {
						return button.getAttribute("data-titulacao");
					});
			}

			_handleTitulacaoChange(evt) {
				var button = evt.currentTarget;

				document.querySelector("#cursos-titulacao-filtro").value = "";
				// Treat these buttons like radio buttons where only 1 can be selected.
				if (button.classList.contains("active")) {
					button.classList.remove("active");
				} else {
					this.titulacoes.forEach(function (btn) {
						btn.classList.remove("active");
					});

					button.classList.add("active");
				}

				this.filters.titulacoes = this._getCurrentTitulacaoFilters();
				this.filter();
			}

			// Executa os filtros.
			filter() {
				if (this.hasActiveFilters()) {
					this.shuffle.filter(this.itemPassesFilters.bind(this));
				} else {
					this.shuffle.filter(Shuffle.ALL_ITEMS);
				}
			}

			// Valida se existe algum filtro ativo.
			hasActiveFilters() {
				return Object.keys(this.filters).some(function (key) {
					return this.filters[key].length > 0;
				}, this);
			}

			// Valida se o objeto passa pelo filtro.
			itemPassesFilters(element) {
				var tipos = this.filters.tipos;
				var modalidades = this.filters.modalidades;
				var titulacoes = this.filters.titulacoes;

				var tipo = element.getAttribute("data-tipo");
				var modalidade = element.getAttribute("data-modalidade");
				var titulacao = element.getAttribute("data-titulacao");

				

				if (tipos.length > 0 && !tipo.includes(tipos)) {
					return false;
				}
				if (titulacoes.length > 0 && !titulacao.includes(titulacoes)) {
					return false;
				}

				if (modalidades.length > 0) {
					// Caso tenha um divisor (híbrido/flex)
					if (modalidades[0].indexOf("/") > 0) {
						if (
							!modalidades[0].split("/").some((x) => modalidade.includes(x))
						) {
							return false;
						}
					} else {
						if (!modalidade.includes(modalidades)) {
							return false;
						}
					}
				}

				return true;
			}

			// Filtra os títulos pela busca.
			_handleSearchKeyup(evt) {
				var searchText = evt.target.value
					.toLowerCase()
					.normalize("NFD")
					.replace(/[\u0300-\u036f]/g, "");

				this.shuffle.filter(function (element) {
					var titleElement = element.getAttribute("data-name");

					var titleText = titleElement
						.normalize("NFD")
						.replace(/[\u0300-\u036f]/g, "")
						.toLowerCase()
						.trim();

					return titleText.indexOf(searchText) !== -1;
				});
			}
		}

		// Instancia o componente
		new CustomFilter(document.getElementById("cursos-items-container"));
		
	}

	render() {
		
		return (
			<Container fluid className="mt-1">
				<Row className="text-center mx-lg-4">
					<Col>
						<div className="input-group md-form  form-1 pl-0">
							<div className="input-group-prepend" >
								<span className="input-group-text bg-secondary" id="basic-text1" >
									<MDBIcon className="text-white" icon="search" />
								</span>
							</div>
							<input
								className={"form-control my-0 py-1" + (GGgetMode() ? " text-white" : "")}
								aria-label="Pesquisar"
								id="cursos-busca-filtro"
								type="text"
								placeholder="Pesquisar"
							/>
						</div>
					</Col>
				</Row>

				<Row className="text-center my-2">
					<Col>
						<h4
							className="my-auto pr-2"
							onClick={this.toggleTiposFiltro}
							// style={GGgetMode() ? { color: "white" } : { color: "#27668e" }}
							style={{color:"#965DE8"}}
						>
							Eu busco por <MDBIcon fas icon="angle-down" />
						</h4>
						<MDBBtnGroup
							size="middle"
							className={`btn-group-filtro flex-wrap ${
								this.state.tiposFiltroVisible ? "" : "hidden"
							  }`}
							id="cursos-tipo-filtro"
							aria-label="Tipos"
						>
							{[
								["criatividade e tecnologia", "icct-background"],
								["saúde", "ics-background"],
								["humanidades e sociais", "ichs-background"],
							].map((item) => (
								
								<MDBBtn color="primary" 
								className={item[1]} 
								data-tipo={item[0]} 
								key={item[0]}
								style={{marginLeft: "0px"}}
								>
									{item[0]}
								</MDBBtn>
								
							))}
						</MDBBtnGroup>
					</Col>
				</Row>

				<Row className="text-center my-2">
					<Col>
						<h4
							className="my-auto pr-2 text-secondary"
							onClick={this.toggleTitulacaoFiltro}
							// style={GGgetMode() ? { color: "white" } : { color: "#27668e" }}
						>
							do tipo <MDBIcon fas icon="angle-down" />
						</h4>
						<MDBBtnGroup
							size="middle"
							className={`btn-group-filtro flex-wrap ${
								this.state.titulacaoFiltroVisible ? "" : "hidden"
							  }`}
							id="cursos-titulacao-filtro"
							aria-label="Qual tipo te interessa?"
						>
							{["Bacharelado", "Licenciatura", "Tecnólogo"].map((item) => (
								<MDBBtn
									outline
									color={!GGgetMode() ? "secondary" : "gg"}
									className="mx-1"
									data-titulacao={item}
									key={item}
									
								>
									{item}
								</MDBBtn>
							))}
						</MDBBtnGroup>
					</Col>
				</Row>

				<Row className="text-center my-2 ">
					<Col>
						<h4
							className="my-auto pr-2 text-primary"
							onClick={this.toggleModalidadeFiltro}
							// style={GGgetMode() ? { color: "white" } : { color: "#27668e" }}
						>
							na modalidade <MDBIcon fas icon="angle-down" />
						</h4>
						<MDBBtnGroup
							size="middle"
							className={`btn-group-filtro flex-wrap ${
								this.state.modalidadeFiltroVisible ? "" : "hidden"
							  }`}
							id="cursos-modalidade-filtro"
							aria-label="Qual modalidade te interessa?"
						>
							{["Presencial/Flex", "Digital/EAD"].map((item) => (
								<MDBBtn
									outline
									color={!GGgetMode() ? "primary" : "gg"}
									className="mx-1"
									//O original filtra em cima do Híbrido, dessa forma, é necessário fazer um if() antes.
									data-modalidade={item === "Presencial/Flex" ? "Híbrido" : item}
									key={item}
								>
									{item}
								</MDBBtn>
							))}
						</MDBBtnGroup>
						<hr className="mt-3 mb-1"/>
					</Col>
				</Row>

				<Row id="cursos-items-container" className="no-gutters">
					{
						// eslint-disable-next-line react/prop-types
						this.props.cursos.map((curso) => (
							<Col
								md="4"
								key={curso.Title}
								data-tipo={ConvertInstitutoToArea(curso.Instituto)}
								data-modalidade={curso.Opcoes}
								data-titulacao={curso.Opcoes}
								data-name={curso.Title}
							>
								<CardCurso
									key={curso.Title}
									slug={curso.Slug}
									instituto={ConvertInstitutoToArea(curso.Instituto)}
									opcoes={curso.Opcoes}
									name={curso.Title}
								/>
							</Col>
						))
					}
				</Row>
				<Row
					id="cursos-items-noitems"
					className="text-center my-4 linear-box mx-5 py-4"
					style={{ display: "none" }}
				>
					<Col>
						<p>
							Não temos um curso nessas características. Registre seu interesse.
						</p>
					</Col>
				</Row>
			</Container>
		);
	}
}

FiltroCurso.propTypes = {
	cursos: PropTypes.array.isRequired,
};
